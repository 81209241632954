import React from 'react';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="confirm-dialog"
        >
          <DialogTitle id="confirm-dialog">{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setOpen(false)}
              color="secondary"
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                onConfirm();
              }}
              color="default"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
    </MuiThemeProvider>
  );
};
const getMuiTheme = () => createMuiTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.2)'
      }
    },
  }
});

export default ConfirmDialog;