import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {toAbsoluteUrl} from "../../../_helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from '@material-ui/core/Switch';
import {Topbar} from "./Topbar";
import {HeaderMenuWrapper} from "./header-menu/HeaderMenuWrapper";
import {AnimateLoading} from "../../../_partials/controls";
import Grid from '@material-ui/core/Grid';

export function HeaderFrontend() {
  const uiService = useHtmlClassService();
  const classes = useStyles();
  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);
  // console.log("header",layoutProps.headerContainerClasses);
  if (window.location.pathname === "/complaints-portal") {
    layoutProps.menuHeaderDisplay = false;
  }
  const getHeaderLogo = () => {
      let result = "logo.png";

      return toAbsoluteUrl(`/media/logos/${result}`);
  };
  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: '1px 8px 8px 8px',
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: '#ffffff !important',
        '& + $track': {
          backgroundColor: '#52d869 !important',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869 !important',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });
  const [state, setState] = React.useState({
    checkedA: true
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div className={` ${layoutProps.headerContainerClasses} container-frontend d-flex align-items-stretch justify-content-between`}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className={classes.headerLogo}>
              <div className="header-logo">
                  <Link to="/">
                      <img alt="logo" src={getHeaderLogo()}/>
                  </Link>
              </div>
            </div>
          </Grid>

          <Grid item xs={4}>

          </Grid>
          <Grid item xs={4}>
            <div className={classes.headerColumn}>
              <Grid container spacing={3}>
                  <Grid item xs={6}>

                  </Grid>
                  <Grid item xs={6}>
                    <Link to="/auth/login" className="font-weight-bold my-auto ">
                      <FontAwesomeIcon icon="sign-out-alt"  size="lg" color="black"/> <span className="px-2 text-dark">Login</span>
                    </Link>
                  </Grid>
              </Grid>


            </div>

          </Grid>

        </Grid>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  headerColumn: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  headerLogo: {
    padding: theme.spacing(2)
  },
  goOffline: {
    verticalAlign: 'middle',
    marginTop: '-5px',
    fontWeight: 'bold',
  }
}));
