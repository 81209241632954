/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {Layout, LayoutSupport, Frontend} from "../_metronic/layout";
import BasePage from "./BasePage";
import FrontendPage from "./FrontendPage";
import { Logout, AuthPage, SignupPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {
    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    const {isSupport} = useSelector(
        ({auth}) => {
            //console.log(auth.user);
            return {
                isSupport: (auth.user != null && auth.user.utype === 1),
            };
        },
        shallowEqual
    );
    const location = useLocation();
    let auth = false;
    if(location.pathname.includes("auth") || location.pathname == '/' )
        auth = true;
    console.log(auth);
    return (
        <Switch>
            {!isAuthorized && auth ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/signup" component={SignupPage}/>
            <Route path="/logout" component={Logout}/>


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                auth ? (
                    <Redirect to="/auth/login"/>
                ) :
                (
                    <Frontend>
                        <FrontendPage/>
                    </Frontend>
                )
            ) : (
                    <Layout>
                        <BasePage/>
                    </Layout> )

             }
        </Switch>
    );
}
