import React, { useState, useEffect } from "react";
import {NavLink}  from "react-router-dom";
import axios from "axios";
import {
  toAbsoluteUrl
} from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import Icon from '@material-ui/core/Icon';
import { green, red } from '@material-ui/core/colors';
import ConfirmDialog from "../component/ConfirmDialog";

export const ADMIN_URL = "admins";

export function AdminScreen() {

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [loading, setLoader] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [recordId, setId] = useState(null);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const [values, setValues] = React.useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    age: "",
    gender: "",
    utype: "",
    status: ""
  });

  const setField = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.log(event.target.value, values);
  };

  async function fetchData() {
    setLoader(true);
    axios.get(ADMIN_URL)
    .then(response => {
          console.log(response.data);
          setDataList(response.data.data);
          setLoader(false);
        //set state object there...
      })
      .catch(error => {
        setErrors(error);
      });
  }
  useEffect(() => {
    fetchData();
  }, [] );
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setValues({
      fname: "",
      lname: "",
      email: "",
      phone: "",
      age: "",
      gender: "",
      utype: "",
      status: ""
    });
    setId(null);
  };
  const handleSubmit = () => {
    addData();
  }
  const addData = () => {
    setLoader(true);
    setOpen(false);
    const paramsArray = JSON.stringify(values);
    console.log(paramsArray);
    axios.post(`${ADMIN_URL}/create`, {paramsArray})
    .then(response => {
          setLoader(false);
          console.log(response);
          setDataList(response.data.data);
          setValues({
            fname: "",
            lname: "",
            email: "",
            phone: "",
            age: "",
            gender: "",
            utype: "",
            status: ""
          });
        //set state object there...
      })
      .catch(error => {
          setLoader(false);
          setErrors("Something went wrong please try again");
          console.log(error);
      });
  }

  const handleUpdate = () => {
    console.log(values, recordId);
    setLoader(true);
    setOpen(false);
    const paramsArray = JSON.stringify(values);
    console.log(paramsArray);
    axios.put(`${ADMIN_URL}/${recordId}`, {paramsArray})
    .then(response => {
          console.log(response.data);
          setDataList(response.data.data);
          setLoader(false);
          setValues({
            fname: "",
            lname: "",
            email: "",
            phone: "",
            age: "",
            gender: "",
            utype: "",
            status: ""
          });
          setId(null);
        //set state object there...
      })
      .catch(error => {
        setErrors(error);
        console.log(error);
        setId(null);
      });
  }

  const handleEdit = (data) => {
    console.log(data);
    setLoader(true);
    axios.get(`${ADMIN_URL}/${data}`)
    .then(response => {
          console.log(response.data.data);
          // setDataList(response.data.data);
          setValues(response.data.data);
          setId(response.data.data.id);
          setLoader(false);
          setOpen(true);
          // setLoader(false);
        //set state object there...
      })
      .catch(error => {
        setErrors(error);
      });
  }

  const handleConfirmOpen = (id) => {
    setConfirmOpen(true);
    setId(id);
  }

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setId(null);
  }

  const handleDelete = () => {
    console.log(recordId);
    setLoader(true);
    axios.delete(`${ADMIN_URL}/${recordId}`)
    .then(response => {
          setDataList(response.data.data);
          setLoader(false);
          setId(null);
        //set state object there...
      })
      .catch(error => {
        setErrors(error);
        setId(null);
      });
  }

  const classes = useStyles();
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
       display: false
      }
     },
    {
     name: "fullname",
     label: "Full Name",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "email",
     label: "Email",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "gender",
     label: "Gender",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
      name: "utype",
      label: "User Type",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return (
          value === 1 ? <Icon className="fa fa-check-circle" style={{ color: green[500] }} /> : <Icon className="fa fa-times-circle" style={{ color: red[500] }} />
        );
      }
    }
    },
     {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          // console.log(value, tableMeta);
          return (
              <>
                  <span className={classes.actionIcons}>
                    <a onClick={() => handleEdit(tableMeta.rowData[0])} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                        />
                      </span>
                    </a>
                    <a className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={() => handleConfirmOpen(tableMeta.rowData[0])}>
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                      </span>
                    </a>
                    <ConfirmDialog
                      title="Are you sure you want to delete this record?"
                      open={confirmOpen}
                      onClose={() => handleConfirmClose()}
                      setOpen={setConfirmOpen}
                      onConfirm={() => handleDelete()}
                    ></ConfirmDialog>
                  </span>
              </>
          );
        }
      }
     },
   ];
   const options = {
     filterType: 'checkbox',
     selectableRows: false
   };


   const Modalbody = (
    <div style={modalStyle} className={classes.paper}>
      <a style={{ float: "right" }} onClick={ () => handleClose() }>x</a>
      {recordId ? <h2 id="simple-modal-title">Edit Record</h2> : <h2 id="simple-modal-title">Add New</h2>}
        <br />
        <form noValidate validated={validated} className={classes.rootForm} autoComplete="off">
            <TextField
              required
              id="outlined-basic"
              value={values.fname}
              onChange={setField("fname")}
              label="First Name"
              variant="outlined"
              helperText="required"
            />
          <TextField required helperText="required" id="outlined-basic" value={values.lname} onChange={setField("lname")} label="Last Name" variant="outlined" />
          <TextField type="email" required helperText="required" id="outlined-basic" value={values.email} onChange={setField("email")} label="Email" variant="outlined" />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={values.gender}
              onChange={setField("gender")}
              label="Gender"
              required
              helperText="required"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </Select>
          </FormControl>

          <TextField
            id="date"
            required
            helperText="required"
            label="Date of Birth"
            type="date"
            variant="outlined"
            defaultValue="2017-05-24"
            className={classes.textField}
            value={values.age}
            onChange={setField("age")}
            InputLabelProps={{
              shrink: true,
            }}
          />

          { values.validityType === "Defined"
          ?
          <TextField
            id="date"
            required
            helperText="required"
            label="Expiry Date"
            type="date"
            variant="outlined"
            defaultValue="2017-05-24"
            className={classes.textField}
            value={values.expiryDate}
            onChange={setField("expiryDate")}
            InputLabelProps={{
              shrink: true,
            }}
          />
          : null }
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={values.utype}
              onChange={setField("utype")}
              label="Type"
              required
              helperText="required"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              <MenuItem value="2">Sub Admin</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={values.status}
              onChange={setField("status")}
              label="Status"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="1">Active</MenuItem>
              <MenuItem value="0">Inactive</MenuItem>
            </Select>
          </FormControl>
           <Button className="btn btn-primary btn-elevate" onClick={ recordId ? handleUpdate : handleSubmit}>
                {recordId ? 'Update' : 'Submit'}
            </Button>
        </form>
    </div>
  );


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={9}><h1>Admins List</h1></Grid>
        <Grid item xs={3}>
          <Button className="btn btn-primary btn-elevate" onClick={handleOpen}>
            ADD NEW
          </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {Modalbody}
            </Modal>
        </Grid>
      </Grid>
      <LoadingOverlay style={{ width: "100%", height: 'auto', backgroundColor: '#222222' }}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={dataList?.length > 0 ? dataList : []}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
        <Loader loading={loading}/>
      </LoadingOverlay>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  actionIcons: {
    display: 'flex'
  },
  paper: {
    position: 'absolute',
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
  },
  rootForm: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTable: {
      root: {
      },
      paper: {
        boxShadow: "none",
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(odd)': {
          backgroundColor: '#f9f9f9',
        }
      }
    },
    MUIDataTableBodyCell: {
    },
    MuiTableCell: {
      root: {
        border: "1px solid #ccc"
      }
    },
    MUIDataTableHeadCell: {
      root: {
        // fontSize: "16px"
      }
    },
    MuiTypography: {
      h6: {
        // fontSize: "24px"
      }
    }
  }
});

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
