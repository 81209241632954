/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/dashboard">
            <span className="svg-icon menu-icon">
              <FontAwesomeIcon color="#6A6D6C" icon="tachometer-alt" />

              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/> */}
            </span>
              <span className="menu-text"><h4 className="my-auto">Dashboard</h4></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/admins", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admins">
            <span className="svg-icon menu-icon">
              <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-lock"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Admin</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/owners", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/owners">
            <span className="svg-icon menu-icon">
              <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-handshake"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Home Owners</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/customers", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/customers">
            <span className="svg-icon menu-icon">
              <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-user"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Customers</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/properties", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/properties">
            <span className="svg-icon menu-icon">
              <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fa fa-building"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Properties / Homes</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/properties/featured", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/properties/featured">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-star"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Featured Homes</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/sales", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="#">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-credit-card"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Sales History</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/bookings", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="/admin/bookings">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-book"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Bookings History</h4></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/admin/reported", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link py-4" to="#">
            <span className="fas-icon menu-icon fas">
                <i style={{color:"#6A6D6C", fontSize:"22px"}} className="fas fa-flag"></i>
            </span>
              <span className="menu-text"><h4 className="my-auto">Reported Properties</h4></span>
            </NavLink>
          </li>

        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
