import axios from "axios";

export const LOGIN_URL = "login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/admin/forgot-password";

export const ME_URL = "me";
export const VERIFY_TOKEN_URL = "acceptInvitation";

export function login(email, password) {
    var paramsArray = JSON.stringify( { email, password });
  return axios.post(LOGIN_URL, {paramsArray});
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

// Accept Invitation => verify the admin from the server
export function verifyAdmin(token) {
  return axios.get(`${VERIFY_TOKEN_URL}/${token}`);
}
